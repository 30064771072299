body {
  margin: 0;
  font-family: Montserrat, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fbf7f7;
}

a {
  text-decoration: none;
  color: none;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: #c5c5c5;
  border-radius: 10px;
}

a:visited {
  text-decoration: none;
  color: black;
}

.link {
  padding: 10px;
  color: #000;
  font-weight: normal;
}
.lin:hover {
  background-color: #f7258456;
  color: white;
}

.hiddenScrollBarAndChildren {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.hiddenScrollBarAndChildren * {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.hiddenScrollBarAndChildren::-webkit-scrollbar {
  display: none;
}

.hiddenScrollBarAndChildren *::-webkit-scrollbar {
  display: none;
}

.MuiButtonBase-root.Mui-disabled {
  color: rgb(171, 171, 171) !important;
}
.MuiPopover-paper {
  box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1) !important;
}
.MuiAccordion-root:before {
  display: none;
}

div[class*="indicatorContainer"] {
  padding: 0;
}

.cofidocHref {
  cursor: pointer;
  font-weight: 600;
  border-radius: 100;
  padding: 10px 16px;
  text-decoration: none;
  border-radius: 100px;
  transition: all ease 200ms;
}

.side-background {
  background: linear-gradient(to left top, #f3e7e7, transparent) bottom right no-repeat;
  padding-left: 20px;
}

.background-over {
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 2;
  background-color: rgba(241, 238, 238, 0.498);
}
.background-over-mobile {
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 2;
  background-color: #f3e7e7c3;
}

.transitionHeight {
  transition: height ease 1000ms;
}

.height40 {
  height: 40vh;
}

.height50 {
  height: 50vh;
}

.height65 {
  height: 65vh;
}

.height100 {
  height: 105vh;
}

.overBg {
  z-index: 10;
  position: relative;
}

.hoverDate {
  cursor: pointer;
  transition: transform ease-in-out 150ms;
}
.hoverDate:hover {
  transform: scale(1.03);
}
/* loader */
#loader-1:before,
#loader-1:after {
  content: "";
  position: absolute;
  top: -2px;
  left: -0px;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border: 7px solid transparent;
  border-top-color: #00376a;
}

#loader-1:before {
  z-index: 100;
  animation: spin 1s infinite;
}

#loader-1:after {
  border: 7px solid rgb(233, 233, 233);
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.bar-loader {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 50px;
  align-items: center;
  margin-top: 50px;
}
.bar-loader .bar {
  border-radius: 3px;
  width: 8px;
  height: 6px;
  background: #dedede;
  margin: 2px;
  animation: bar 1s infinite linear;
}
.bar-loader .bar:nth-child(1) {
  animation-delay: 0s;
}
.bar-loader .bar:nth-child(2) {
  animation-delay: 0.2s;
}
.bar-loader .bar:nth-child(3) {
  animation-delay: 0.4s;
}
.bar-loader .bar:nth-child(4) {
  animation-delay: 0.6s;
}
.bar-loader .bar:nth-child(5) {
  animation-delay: 0.8s;
}

@keyframes bar {
  0% {
    height: 6px;
  }
  50% {
    height: 50px;
  }
  100% {
    height: 6px;
  }
}

.react-calendar__tile--active {
  background: #f72585 !important;
  border-radius: 0px;
}

.react-calendar {
  border: none !important;
}

.react-resizable {
  position: relative;
}

.react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+");
  background-position: bottom right;
  padding: 0 3px 3px 0;
}

.react-resizable-handle-sw {
  bottom: 0;
  left: 0;
  cursor: sw-resize;
  transform: rotate(90deg);
}

.react-resizable-handle-se {
  bottom: 0;
  right: 0;
  cursor: se-resize;
}

.react-resizable-handle-nw {
  top: 0;
  left: 0;
  cursor: nw-resize;
  transform: rotate(180deg);
}

.react-resizable-handle-ne {
  top: 0;
  right: 0;
  cursor: ne-resize;
  transform: rotate(270deg);
}

.react-resizable-handle-w,
.react-resizable-handle-e {
  top: 50%;
  margin-top: -10px;
  cursor: ew-resize;
}

.react-resizable-handle-w {
  left: 0;
  transform: rotate(135deg);
}

.react-resizable-handle-e {
  right: 0;
  transform: rotate(315deg);
}

.react-resizable-handle-n,
.react-resizable-handle-s {
  left: 50%;
  margin-left: -10px;
  cursor: ns-resize;
}

.react-resizable-handle-n {
  top: 0;
  transform: rotate(225deg);
}

.react-resizable-handle-s {
  bottom: 0;
  transform: rotate(45deg);
}
