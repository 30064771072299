.logo-cofidoc {
  width: 75%;
  height: 130px;
  background-image: url("./images/cofidoc-black.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.logo-inovie {
  width: 75%;
  height: 130px;
  background-image: url("./images/inovie-logo.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.background-mountain-cofidoc {
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 1;
  background-image: url("./images/cofidoc-mountains.svg");
  background-size: cover;
}

.background-mountain-inovie {
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 1;
  background-image: url("./images/inovie-mountains.svg");
  background-size: cover;
}

.downloadApple {
  background: url("./images/apple.svg") no-repeat center center;
  display: flex;
  background-size: cover;
  height: 60px;
  width: 200px;
  border-radius: 10px;
  overflow: hidden;
}

.downloadAndroid {
  background: url("./images/google.png") no-repeat center center;
  display: flex;
  background-size: cover;
  height: 60px;
  width: 200px;
  border-radius: 10px;
  overflow: hidden;
}

.background-mountain-mobile-cofidoc {
  width: 100vw;
  height: 79vh;
  position: absolute;
  z-index: 1;
  background-image: url("./images/cofidoc-mountains.svg");
  background-size: cover;
  background-position: center;
}
.background-mountain-mobile-inovie {
  width: 100vw;
  height: 79vh;
  position: absolute;
  z-index: 1;
  background-image: url("./images/inovie-mountains.svg");
  background-size: cover;
  background-position: center;
}

.background-cloud {
  width: 100vw;
  bottom: 0;
  background-repeat: no-repeat;
  position: absolute;
  background-size: cover;
  z-index: 2;
  background-image: url("./images/clouds.svg");
}

.inovie {
  width: 50px;
  height: 50px;
  background-image: url("./images/1024.png");
  background-size: cover;
  background-position: center;
}

.background-logo {
  width: 200px;
  height: 50px;
  background-image: url("./images/cofidoc-black.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.background-logo-mobility {
  width: 50px;
  height: 50px;
  background-image: url("./images/cofidoc-mobility-app.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.background-logo-taxi {
  width: 50px;
  height: 50px;
  background-image: url("./images/cofidoc-taxi-app.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.background-logo-inovie {
  width: 150px;
  height: 150px;
  background-image: url("./images/inovie-logo.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.background-logo-inovidel {
  width: 50px;
  height: 50px;
  background-image: url("./images/inovieWhite.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.image-cofidoc-philippe-soual {
  width: 100%;
  height: 100%;
  background-image: url("./images/cofidoc-philippe-soual.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.image-cofidoc-metier {
  width: 100%;
  height: 100%;
  background-image: url("./images/cofidoc-metier.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
